import {
    INTRO_CLASS_AVATAR_WITH_SELLER,
    INTRO_CLASS_AVATAR_WITHOUT_SELLER,
    INTRO_CLASS_CHANGE_COMPANY,
    INTRO_CLASS_FILTER,
    INTRO_CLASS_INBOX_LIST,
    INTRO_CLASS_MENU_ICON,
    INTRO_CLASS_PANEL_MODE,
    INTRO_CLASS_SEGMENT,
    INTRO_CLASS_SHOW_OTHER_SELLER_INBOX,
    INTRO_CLASS_UNREPLIED,
} from "variables/classIntro";

export const STEPS = [
    {
        selector: `.${INTRO_CLASS_PANEL_MODE}`,
        text:
            "Bạn có thể chọn chế độ mở cùng lúc nhiều cửa sổ chat với nhiều khách hàng hoặc chỉ một khách hàng tại đây.",
    },
    {
        selector: `.${INTRO_CLASS_FILTER}`,
        text:
            "Tại đây, bạn có thể lọc chỉ hiển thị khách hàng từ một kênh bán hàng duy nhất, hoặc có thể chọn tất cả để không lọc theo kênh bán hàng.",
    },
    {
        selector: `.${INTRO_CLASS_INBOX_LIST}`,
        text:
            "Danh sách khách hàng sẽ được hiển thị tại đây. Danh sách sẽ được hiển thị theo khách hàng có tin nhắn mới nhất ở trên cùng.",
    },
    {
        selector: `.${INTRO_CLASS_AVATAR_WITHOUT_SELLER}`,
        text:
            "Khách hàng chỉ có một avatar duy nhất là khách hàng chưa được nhân viên nào chăm sóc. Bạn sẽ có thể nhận chăm sóc khách hàng này.",
    },
    {
        selector: `.${INTRO_CLASS_AVATAR_WITH_SELLER}`,
        text:
            "Khách hàng có avatar gồm hai hình là khách hàng đã có nhân viên chăm sóc, bạn chỉ có thể chat với khách hàng do bạn nhận chăm sóc.",
    },
    {
        selector: `.${INTRO_CLASS_UNREPLIED}`,
        text:
            "Khách hàng có hiển thị số nhấp nháy là khách hàng đã nhắn tin cho công ty nhưng chưa được trả lời. Số nhấp nháy chính là số tin nhắn chưa được trả lời.",
    },
    {
        selector: `.${INTRO_CLASS_SEGMENT}`,
        text: "Bạn có thể click vào đây để phân nhóm khách hàng.",
    },
    {
        selector: `.${INTRO_CLASS_CHANGE_COMPANY}`,
        text: "Bạn có thể click vào đây để chuyển sang công ty khác.",
    },
    {
        selector: `.${INTRO_CLASS_MENU_ICON}`,
        text: "Click vào đây để mở menu",
    },
];
