import { CHAT_MODE } from "./staticValueMessage";
import { ALL_PERMISSION } from "./staticValue";
import LocalStorage from "../config/LocalStorage";

export default class DV {
    static nextPath = "";
    static network = true;
    static token = "";
    static tokenExpireTime = null;
    static refreshToken = ""
    static refreshTokenExpireTime = {}
    static staff = {};
    static company = LocalStorage.getCompany();
    static user = null;
    static classes = {};
    static socketChat = null;
    static socketInbox = null;
    static showOtherSellerInbox = false;
    static isFiltering = false;
    static filterParams = null;
    static staffDeviceId = "";

    static selector = "";
    static joyride = null;
    static joyrideRun = null;
    static showGuideTour = false;
    static searching = false;
    static partner = null;
    static currentSubscription = null;
    static filterMessage = null;
    static permission = ALL_PERMISSION;
    static isOwner = false;
    static isSubscriptionExpired = false;
    static notifySubscriptionExpired = null;

    static store = {};
    static fileHolder = {};
    static tags = {};
    static chatMode = CHAT_MODE.multi;
    static params = {};
    static history = null;
    static panelChatRef = [];
    static isSocketChatClosed = false;
    static openFilterInboxModal = () => {};
    static callService = null;
    static indexDB = null;

    static showNotify = () => {};

    static hideInbox = () => {};
    static callAccount = {}
}
