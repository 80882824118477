export const primaryColor = "#ef6c00";
export const warningColor = "#ff9800";
export const dangerColor = "#f44336";
export const successColor = "#4caf50";
export const infoColor = "#2979ff";
export const roseColor = "#e91e63";
export const grayColor = "#999999";
export const twitterColor = "#55acee";
export const linkedinColor = "#0976b4";
export const tumblrColor = "#35465c";
export const githubColor = "#333333";
export const behanceColor = "#1769ff";
export const dribbbleColor = "#ea4c89";
export const redditColor = "#ff4500";
export const systemMessageColor = "#fae3cf";

export const COLOR_OBJECT = {
    primary: "#ef6c00",
    warning: "#ff9800",
    danger: "#f44336",
    success: "#4caf50",
    info: "#2979ff",
};

export const findColorName = (value) => {
    let name = null;
    if (value)
        Object.keys(COLOR_OBJECT).map((key) => {
            if (COLOR_OBJECT[key] === value) name = key;
        });

    return name;
};

export const getRandomColor = () => {
    let keys = Object.keys(COLOR_OBJECT);
    return COLOR_OBJECT[keys[(keys.length * Math.random()) << 0]];
};
