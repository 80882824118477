import loadable from "@loadable/component";
import React from "react";
import LoadableLoading from "components/LoadableScreen/LoadableLoading";

export const Loadable_Login = loadable(() => import("screen/Login/Login"), {
    fallback: LoadableLoading,
});

export const Loadable_Company = loadable(
    () => import("screen/Company/Company"),
    {
        fallback: LoadableLoading,
    }
);

export const Loadable_OrderForm = loadable(
    () => import("screen/Form/OrderForm/OrderForm"),
    {
        fallback: LoadableLoading,
    }
);

export const Loadable_Home = loadable(() => import("screen/Home/Home"), {
    fallback: LoadableLoading,
});

export const Loadable_Loading = loadable(
    () => import("screen/Loading/Loading"),
    {
        fallback: LoadableLoading,
    }
);
