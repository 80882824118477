import ReactGA from "react-ga";
import DV from "variables/DV";
import { GA_ID } from "config/Env";

ReactGA.initialize(GA_ID);

export const ga_Event = {
    category: {
        action: "Action",
        company: "Company",
    },
    action: {
        click_nonred_read_conversation: {
            actionName: "Click conversation",
            label: "Click nonred read conversation",
        },
        click_nonred_unread_conversation: {
            actionName: "Click conversation",
            label: "Click nonred unread conversation",
        },
        click_red_read_conversation: {
            actionName: "Click conversation",
            label: "Click red read conversation",
        },
        click_red_unread_conversation: {
            actionName: "Click conversation",
            label: "Click red unread conversation",
        },
        click_send_private_msg: {
            actionName: "Click conversation",
            label: "Click send private msg",
        },
        close_conversation: {
            actionName: "Click action menu",
            label: "Close conversation",
        },
        view_about_us: {
            actionName: "Click menu",
            label: "View about us",
        },
        click_switch_company: {
            actionName: "Click menu",
            label: "Click switch company",
        },
        logout: {
            actionName: "Click menu",
            label: "Logout",
        },
        click_refer_seller: {
            actionName: "Click action menu",
            label: "Click refer seller",
        },
        click_send_product: {
            actionName: "Click action menu",
            label: "Click send product",
        },
        click_tag_product: {
            actionName: "Click action menu",
            label: "Click tag product",
        },
        click_open_history: {
            actionName: "Open history",
            label: "Click open history",
        },
        click_open_post: {
            actionName: "Open post",
            label: "Open post facebook",
        },
        click_order: {
            actionName: "Click action menu",
            label: "Click order",
        },
        click_send_emoticon: {
            actionName: "Click action menu",
            label: "Click send emoticon",
        },
        enter_company_by_code: {
            actionName: "Enter company",
            label: "Enter company by code",
        },
        enter_company_by_selection: {
            actionName: "Enter company",
            label: "Enter company by selection",
        },
        switch_single_column: {
            actionName: "Switch column",
            label: "Switch to single column",
        },
        switch_multiple_column: {
            actionName: "Switch column",
            label: "Switch to multiple column",
        },
        send_private_msg: {
            actionName: "Send msg",
            label: "Send private msg",
        },
        send_emoticon: {
            actionName: "Send msg",
            label: "Send emoji",
        },
        send_msg: {
            actionName: "Send msg",
            label: "Send msg",
        },
        send_msg_ok: {
            actionName: "Send msg",
            label: "Send msg ok",
        },
        send_msg_notok: {
            actionName: "Send msg",
            label: "Send msg notok",
        },
        send_msg_error: {
            actionName: "Send msg",
            label: "Send msg error",
        },
        dragdrop_conversation: {
            actionName: "Dragdrop conversation",
            label: "Dragdrop conversation",
        },
    },
};

export const handleGA_ActionEvent = (action, text = "") => {
    let { category, actionName, label } = action;
    category = category || ga_Event.category.action;
    if (text) label = label + "-" + text;
    ReactGA.event({
        category,
        action: actionName,
        label,
    });
};

export const sendMessageLagTiming = (time) => ({
    category: "QoS",
    variable: "doopage_messages_lag_mili_seconds",
    value: time,
    label: "Socket-chat",
});

const { detect } = require("detect-browser");
const browserInfo = detect() || {};
export const sendCompanyEvent = () => {
    ReactGA.event({
        category: "Company",
        action: DV.company.name + " - " + DV.company.id,
        label:
            browserInfo.name +
            " - " +
            browserInfo.version +
            " - " +
            browserInfo.os,
    });
};

ReactGA.initialize(GA_ID);

export const sendGAAction = (event) => ReactGA.event(event);

export const sendButtonAction = (action) => {
    sendGAAction({
        category: "Button",
        action: "Click button " + action,
        label: "Click button " + action + " at " + window.location.pathname,
    });
};

export const sendSearchAction = (keyword) => {
    sendGAAction({
        category: "Search",
        action: `Search "${keyword}"`,
        label: `Search "${keyword}" at ` + window.location.pathname,
    });
};

export const sendSelectAction = (action) => (value) => {
    sendGAAction({
        category: "Select",
        action: "Select " + value + " in " + action,
        label:
            "Select " +
            value +
            " in " +
            action +
            " at " +
            window.location.pathname,
    });
};
