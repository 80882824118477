import { routesPath } from "./routesPath";
import {
    Loadable_Company,
    Loadable_Home,
    Loadable_Loading,
    Loadable_Login,
    Loadable_OrderForm,
} from "config/LoadableConfig";
import { APP_VERSION } from "../variables/staticValue";
import { getVersionWithoutBuildNumber } from "../helper/helper";

let pathParent = `/${getVersionWithoutBuildNumber(APP_VERSION)}`;

export const appRoutes = [
    // {
    //     path: pathParent,
    //     name: "Doopage",
    //     component: Text,
    // },
    {
        private: true,
        path: routesPath.home,
        name: "Trang chủ",
        component: Loadable_Home,
    },
    {
        private: true,
        path: routesPath.company,
        name: "Tạo công ty",
        component: Loadable_Company,
    },
    {
        path: routesPath.order_form,
        name: "Đặt hàng",
        component: Loadable_OrderForm,
    },
    {
        path: routesPath.login,
        name: "Đăng nhập",
        component: Loadable_Login,
    },
    {
        path: pathParent,
        name: "DooPage",
        component: Loadable_Loading,
    },
    {
        path: "",
        name: "DooPage",
        component: Loadable_Loading,
    },
];
