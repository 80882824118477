export const setLogStyle = (color) =>
    `background: ${color}; padding: 2px 5px; color: #fff;`;
export const useLogStyle = "%c";

const removeLog = () => {
    console.log(
        useLogStyle + "Dừng lại!",
        "font-weight: bold; font-size:50px;color:red;"
    );
    console.log(
        useLogStyle +
            "Đây là một tính năng của trình duyệt dành cho các nhà phát triển.",
        "font-weight: bold; font-size:20px;"
    );
    console.log = () => {};
    console.dir = () => {};
};

export const isDebug = () =>
    !process.env.NODE_ENV || process.env.NODE_ENV !== "production";

if (isDebug()) console.log("DEBUG MODE");
else removeLog();
