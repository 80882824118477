const tooltipsStyle = {
    tooltip: {
        padding: "5px 10px",
        minWidth: "70px",
        // color: "#555555",
        lineHeight: "1.5em",
        // background: "#FFFFFF",
        border: "none",
        borderRadius: "5px",
        boxShadow:
            "0 8px 10px 1px rgba(0, 0, 0, 0.14), 0 3px 14px 2px rgba(0, 0, 0, 0.12), 0 5px 5px -3px rgba(0, 0, 0, 0.2)",
        maxWidth: "200px",
        textAlign: "center",
        fontSize: 11,
        fontStyle: "normal",
        fontWeight: "400",
        textShadow: "none",
        textTransform: "none",
        letterSpacing: "normal",
        wordBreak: "normal",
        wordSpacing: "normal",
        wordWrap: "normal",
        whiteSpace: "normal",
        lineBreak: "auto",
    },
};

export default tooltipsStyle;
