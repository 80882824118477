import React from "react";
import ReactGA from "react-ga";
import { Redirect, Route } from "react-router-dom";
import DV from "../../variables/DV";

export const PrivateRoute = ({ component: Component, ...rest }) => {
    return (
        <Route
            {...rest}
            render={(props) => {
                console.log("nextPath:", DV.nextPath, rest.path, DV.token);
                if (DV.nextPath === rest.path || DV.token) {
                    DV.nextPath = rest.path;
                    return <Component {...props} />;
                } else {
                    // ReactGA.pageview("/");
                    return (
                        <Redirect
                            to={{
                                pathname: "/",
                                state: { from: props.location },
                            }}
                        />
                    );
                }
            }}
        />
    );
};
