import * as Sentry from "@sentry/browser";
import classNames from "classnames";
import React, { Component } from "react";
import AssetImage from "../assets/AssetImage";
import { showReportDialog } from "config/SentryConfig";
import { xPath } from "testingClasses";
import PropTypes from "prop-types";
import { Button } from "@doopage/react-ui-kit";
export default class CatchErrorBoundary extends Component {
    constructor(props) {
        super(props);
        this.state = { error: null, eventId: null };
    }

    componentDidCatch(error, errorInfo) {
        this.setState({ error });
        Sentry.withScope((scope) => {
            scope.setExtras(errorInfo);
            const eventId = Sentry.captureException(error);
            this.setState({ eventId });
        });
    }

    render() {
        if (!this.state.error) return this.props.children;

        if (this.props.showErrorScreen)
            return (
                <div
                    className={classNames("flex-center", xPath.stateView.error)}
                    style={{ height: "100vh" }}
                >
                    <img src={AssetImage.Error} width={"50%"} alt={"img"} />

                    <div style={{ margin: 50 }} className="bold">
                        {"Có lỗi xảy ra, vui lòng tải lại trang"}
                    </div>
                    <div className="flex-center-row">
                        <Button
                            color="success"
                            onClick={() => {
                                window.location.reload();
                            }}
                            round
                            margin
                        >
                            Tải lại trang
                        </Button>

                        <Button
                            color="info"
                            onClick={() => {
                                showReportDialog(this.state.eventId);
                            }}
                            round
                        >
                            Báo lỗi
                        </Button>
                    </div>
                </div>
            );

        return null;
    }
}

CatchErrorBoundary.defaultProps = {
    showErrorScreen: false,
};

CatchErrorBoundary.propTypes = {
    showErrorScreen: PropTypes.bool,
};
