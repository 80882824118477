import { STEPS } from "addOnComponent/TouristIntro/stepIntro";
import { getInboxList } from "api/api";
import React, { PureComponent } from "react";
import Joyride from "react-joyride";
import { connect } from "react-redux";
import { compose } from "redux";
import { getData } from "redux/actionCreator/action";
import Types from "redux/type";
import DV from "variables/DV";
import "./react-joyride.css";
import { clickInbox } from "../../redux/actionCreator/actionSelectInbox";

class TouristIntro extends PureComponent {
    constructor(props) {
        super(props);
        this.state = {
            stepsEnabled: false,
            initialStep: 0,
        };
    }

    run = (step = 0) => {
        // if (!this.state.hadRun && step >= 0)
        DV.showGuideTour = true;
        this.setState({ stepsEnabled: true, initialStep: step });
    };

    onExit = () => {
        DV.showGuideTour = false;
        this.setState(() => ({ stepsEnabled: false }));
    };

    callback = (data) => {
        if (
            data.action === "close" ||
            (data.type === "finished" && data.action === "next")
        ) {
            this.endTour();
        }
        DV.selector = data.type === "tooltip:before" ? data.step.selector : "";

        this.setState({
            selector: data.type === "tooltip:before" ? data.step.selector : "",
        });
    };

    endTour = () => {
        this.onExit();
        this.reloadInboxList();
        setTimeout(() => {
            console.dir("zxc logo:", document.getElementById("logo"));
            document.getElementById("logo").click();
        }, 500);
    };

    reloadInboxList = async () => {
        await this.props.getInboxList();
        let { inboxList } = this.props;
        if (Array.isArray(inboxList) && inboxList.length > 0) {
            this.props.selectInbox(inboxList[0]);
        }
    };

    render() {
        const { stepsEnabled, initialStep } = this.state;

        return (
            <Joyride
                run={stepsEnabled}
                stepIndex={initialStep}
                autoStart={true}
                ref={(ref) => (this.joyride = ref)}
                callback={this.callback}
                debug={false}
                // disableOverlay={selector === '.card-tickets'}
                locale={{
                    back: <span>Trước</span>,
                    close: <span>Đóng</span>,
                    last: <span>Hoàn tất</span>,
                    next: <span>Kế tiếp</span>,
                    skip: <span>Bỏ qua</span>,
                }}
                showOverlay={true}
                steps={STEPS}
                type={"continuous"}
                disableOverlay={true}
                disableOverlayClose={true}
            />
        );
    }

    componentDidMount() {
        DV.joyride = this.joyride;
        DV.joyrideRun = this.run;
    }
}

const mapStateToProps = (state) => {
    return {
        inboxList: state.inboxList.data,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        getInboxList: (params = "") =>
            dispatch(getData(getInboxList(params), Types.GET_INBOX_LIST)),
        selectInbox: (inbox) => dispatch(clickInbox(inbox)),
    };
};

export default compose(connect(mapStateToProps, mapDispatchToProps))(
    TouristIntro
);
