const keymirror = (obj, prefix = "") => {
    let ret = {};
    let key;
    if (!(obj instanceof Object && !Array.isArray(obj))) {
        throw new Error("keyMirror(...): Argument must be an object.");
    }
    for (key in obj) {
        if (obj.hasOwnProperty(key)) {
            ret[key] = prefix + key;
            if (key.includes("GET")) {
                ret[key + "_SUCCESS"] = prefix + key + "_SUCCESS";
                ret[key + "_FAIL"] = prefix + key + "_FAIL";
            }
        }
    }
    return ret;
};
export default keymirror(
    {
        GET_TOKEN: null,
        GET_JOINED_COMPANIES: null,
        GET_ALL_COMPANIES: null,
        GET_INBOX_LIST: null,
        GET_PRODUCTS: null,
        GET_SELLER_PROFILE: null,
        GET_LIST_SELLER: null,
        GET_SEGMENT: null,
        GET_PRESETS: null,

        SET_COMPANY: null,
        SET_TOKEN: null,
        SET_SOCKET_INBOX: null,
        SET_INBOX_LIST: null,
        SET_MULTI_INBOX: null,
        SET_SELECTED_PRESET: null,
        SET_SETTINGS: null,

        CONNECT_INBOX: null,
        CONNECT_CHAT: null,
        OTHER_SELLER_INBOX: null,
        GET_REPLY_COMMANDS: null,

        //todo: use to receices id from notification
        SET_PARAMS_OPEN_FROM_NOTIFICATION: null,

        //todo: handle network
        SET_NETWORK: null,
        SET_CONNECTION: null,
        CLEAR_DATA: null,

        //todo: handle alert
        HIDE_ALERT: null,
        SHOW_ALERT: null,

        //todo: handle select inbox
        SELECT_INBOX: null,
        SET_MAX_INBOX_OPEN: null,
        SET_CHAT_MODE: null,

        //todo: handle run tour
        SET_RUN_TOUR: null,

        SET_SELLER_INBOX_COUNT: null,

        SET_INBOX_CALLING: null,
        ADD_INBOX_CALLING: null,
        REMOVE_INBOX_CALLING: null,

        SET_USER_STATUS: null,
        SHOW_SOURCE_INBOX: null,
        SET_CALL_SERVICE: null,
        SET_CALLS_IS_HELD: null,
        SET_SELLER_CALLING: null,
        SET_CALL_IS_MERGED: null,
        SHOW_INBOX_CALL_ERROR: null,
        HIDE_INBOX_CALL_ERROR: null,
    },
    "",
);
