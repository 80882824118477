import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { getPerformance } from "firebase/performance";
import { onCLS, onFID, onLCP } from "web-vitals";
import ReactGA from "react-ga";
import { getMessaging, isSupported, getToken } from "firebase/messaging";


// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

const firebaseConfig = {
    apiKey: "AIzaSyABCDLmRdE4ixCPyf1nkrTmLKeG2oeibUk",
    authDomain: "doopage-youthdev.firebaseapp.com",
    databaseURL: "https://doopage-youthdev.firebaseio.com",
    projectId: "doopage-youthdev",
    storageBucket: "doopage-youthdev.appspot.com",
    messagingSenderId: "470930908550",
    appId: "1:470930908550:web:4a623a70eebe5178",
    measurementId: "G-F6MR3FWYSK",
};

const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);

const perf = getPerformance(app);

function sendToGoogleAnalytics({ name, delta, id }) {
    // Assumes the global `ga()` function exists, see:
    // https://developers.google.com/analytics/devguides/collection/analyticsjs
    ReactGA.event({
        category: "Web Vitals",
        action: name,
        // The `id` value will be unique to the current page load. When sending
        // multiple values from the same page (e.g. for CLS), Google Analytics can
        // compute a total by grouping on this ID (note: requires `eventLabel` to
        // be a dimension in your report).
        label: id,
        // Google Analytics metrics must be integers, so the value is rounded.
        // For CLS the value is first multiplied by 1000 for greater precision
        // (note: increase the multiplier for greater precision if needed).
        value: Math.round(name === "CLS" ? delta * 1000 : delta),
        // Use a non-interaction event to avoid affecting bounce rate.
        nonInteraction: true,
        // Use `sendBeacon()` if the browser supports it.
        transport: "beacon",
    });
}

onCLS(sendToGoogleAnalytics);
onFID(sendToGoogleAnalytics);
onLCP(sendToGoogleAnalytics);

export const messaging = getMessaging(app);

export const isSupportFirebaseMessage = isSupported;

export const getFCMToken = async () => {
    try {
        return await getToken(messaging, { vapidKey: "BI-qrPHIYBmLknIQoOsYqIS8xZqmiOhsO2lrMf6vhHoIiOAxlL6S9_-pOZ1HbV9T_OxxRgRz2E1I_ZmVGcmdJSU" });
    } catch (err) {
        console.error("An error occurred while retrieving token. ", err);
    }

    return undefined;
};
