import "css/classes.css";
import "config/LogConfig";
import "config/SentryConfig";
import "config/FirebaseConfig";
import "config/GoogleAnalyticsConfig";
import { MuiTheme } from "config/Theme";
import React from "react";
import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import HttpsRedirect from "react-https-redirect";
import { Provider } from "react-redux";
import CatchErrorBoundary from "addOnComponent/CatchErrorBoundary";
import store from "redux/store";
import { unregister } from "./registerServiceWorker";
import App from "screen/App/App";
import { render } from "react-dom";
import { ThemeProvider } from "@material-ui/core/styles";

window.ResizeObserver = undefined;
render(
    <ThemeProvider theme={MuiTheme}>
        <CatchErrorBoundary showErrorScreen>
            <Provider store={store}>
                <HttpsRedirect>
                    <DndProvider backend={HTML5Backend}>
                        <App />
                    </DndProvider>
                </HttpsRedirect>
            </Provider>
        </CatchErrorBoundary>
    </ThemeProvider>,
    document.getElementById("root"),
);

unregister();
