import { i18n } from "@lingui/core";
import { en, id, vi } from "make-plural/plurals";
import moment from "moment";

const SUPPORT_LANGUAGE = ["en", "vi", "id"];
const DEFAULT_LANGUAGE = "vi";

i18n.loadLocaleData({
    en: { plurals: en },
    vi: { plurals: vi },
    id: { plurals: id },
});

const getDefaultLanguage = () => {
    let lang =
        JSON.parse(localStorage.getItem("language")) ??
        navigator?.language?.split("-")?.[0] ??
        DEFAULT_LANGUAGE;

    if (!SUPPORT_LANGUAGE.includes(lang)) lang = DEFAULT_LANGUAGE;

    return lang;
};

export const loadLanguage = async (locale = getDefaultLanguage()) => {
    if (locale !== "en") await import(`moment/locale/${locale}`);
    moment.locale(locale);

    const { messages } = await import(`locales/${locale}/LC_MESSAGES/messages`);
    i18n.load(locale, messages);
    i18n.activate(locale);

    console.log("Locale: ", locale, messages);
};

export const trans = (message) => (i18n ? i18n._(message) : message);
