import * as Sentry from "@sentry/browser";
import axios from "axios";
import { SERVER_BASE_URL } from "config/Env";
import { getBuildNumber, handleApi } from "../helper/helper";
import DV from "../variables/DV";
import { trans } from "./LinguiConfig";
import LocalStorage from "./LocalStorage";
import { t } from "@lingui/macro";
import { isRefreshTokenExpired, isTokenExpired, renewToken } from "../helper/token";
import { refreshToken } from "../api/api";
import { default as decodeJWT } from "jwt-decode";

export const BASE_URL = SERVER_BASE_URL + "/api/";
export const TIME_OUT = 180000;

 const getBaseUrl = (version) => {
     if(version) return SERVER_BASE_URL + "/api/v"+version+"/";
     return BASE_URL;
 }

const mapLanguage = { vi: "vi-vn", en: "en-us", id: "id-id", ms: "ms-my" };



export const fetchApi = async (
    endPoint,
    data = null,
    method = "get",
    headers = {},
    version
) => {
    if(DV.token && DV.refreshToken && isTokenExpired() && !endPoint.includes('refresh-token')) {
        await renewToken();
    }
    let token = DV.token;
    if (token) {
        headers = {
            ...headers,
            Authorization: "Bearer " + token,
            "Accept-Language": mapLanguage[LocalStorage.getLang()],
            "X-DooPage-Build": getBuildNumber(),
        };
        let doopage_fake_owner_id = localStorage.getItem(
            "doopage_fake_owner_id"
        );
        if (doopage_fake_owner_id)
            headers = { ...headers, "Doopage-Owner-Id": doopage_fake_owner_id };
    }

    return axios({
        baseURL: getBaseUrl(version),
        url: endPoint,
        method,
        headers,
        data,
        timeout: TIME_OUT,
    })
        .then((response) => {
            if (response) {
                if (response.status === 204) return {};
                if (response.data) return response.data;

                Sentry.captureException(
                    new Error(
                        "Có lỗi xảy ra do một nguyên nhân chưa rõ, vùi lòng thử tải lại trang hoặc liên lạc với chúng tôi để được giúp đỡ."
                    )
                );
                throw trans(
                    t`Có lỗi xảy ra do một nguyên nhân chưa rõ, vùi lòng thử tải lại trang hoặc liên lạc với chúng tôi để được giúp đỡ.`
                );
            }
        })
        .catch( async (error) => {

            if (!DV.network)
                throw trans(
                    t`Không thể kết nối với máy chủ, vui lòng kiểm tra lại đường truyền internet và thử lại.`
                );

            Sentry.captureException(error);

            const { response, message } = error;
            if (response) {
                const { status, data } = response;
                if (data) {
                    const { error, detail, message, code } = data;
                    if(code ==='token_expired') {
                        await renewToken()
                        return fetchApi(endPoint, data, method, headers, version)
                    }
                    if (error) throw error;
                    if (detail) throw detail;
                    if (message) throw message;
                }
                if (status >= 500)
                    throw trans(
                        t`Có lỗi không mong muốn xảy ra, vui lòng thử tải lại trang hoặc liên hệ với chúng tôi để được giúp đỡ.`
                    );
                if (status >= 400)
                    throw trans(
                        t`Có lỗi do truy cập vào tài nguyên không được cấp phép, vui lòng tải lại trang hoặc liên hệ với chúng tôi để được giúp đỡ.`
                    );
            }

            if (message) {
                if (error.message.includes("timeout of"))
                    throw trans(
                        t`Không thể kết nối với máy chủ, vui lòng kiểm tra lại đường truyền internet và thử lại.`
                    );

                if (message.toString().includes("Network Error"))
                    throw t`Lỗi kết nối, vui lòng thử lại`;

                if (message.toString().toLowerCase().includes("resources"))
                    throw trans(
                        t`Máy tính của bạn không đủ tài nguyên để xử lý, vui lòng tắt bớt các ứng dụng không cần thiết và thử lại`
                    );

                throw message;
            }

            if (error.toString().includes("Network Error"))
                throw t`Lỗi kết nối, vui lòng thử lại`;

            throw error;
        });
};
