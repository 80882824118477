import LocalStorage from "config/LocalStorage";
import { addAmountOfProduct, mergeInbox } from "helper/helper";
import DV from "variables/DV";
import { getBaseReduxObj, INBOX_LIMIT, STATE_SOCKET } from "variables/staticValue";
import { defaultPresets } from "../variables/staticValue";
import createReducer from "./createReducer";
import Types from "./type";
import { orderBy } from "lodash";
import isEmpty from "lodash/isEmpty";

const INIT_STATE = {
    token: getBaseReduxObj(),
    joinedCompanies: getBaseReduxObj(),
    allCompanies: getBaseReduxObj(),
    inboxList: getBaseReduxObj(),
    products: getBaseReduxObj(),
    listSeller: getBaseReduxObj(),
    segments: getBaseReduxObj(),
    presets: { ...getBaseReduxObj(), data: defaultPresets },
    replyCommands: getBaseReduxObj(),

    stateConnectChat: STATE_SOCKET.none,
    stateConnectInbox: STATE_SOCKET.none,

    network: true,

    listInboxSelected: [],
    maxInboxOpen: LocalStorage.getMaxInboxOpen(),
    chatMode: LocalStorage.getChatMode(),
    other_seller_inbox: false,
    notifyMessage: "",
    notifyColor: null,
    currentCompany: { id: 1 },
    selectedPreset: null,

    inboxCount: 0,
    inboxCalling: LocalStorage.getFromLocal('inboxCalling') || [],
    callsIsHeld: [],

    userStatus: "auto", // auto/offline
    showInboxSource: LocalStorage.getShowInboxSource(),

    callService: null,
    sellerCalling: null,
    inboxCallError: [],

    settings: {}
};

export default createReducer(INIT_STATE, {
    //todo: GET_INBOX_LIST
    [Types.GET_INBOX_LIST]: (state, action) => {
        let { getMore } = action;
        let { data } = state.inboxList;
        return {
            ...state,
            inboxList: {
                ...getBaseReduxObj(),
                loading: true,
                data,
            },
        };
    },
    [Types.GET_INBOX_LIST + "_SUCCESS"]: (state, action) => {
        let { payload, getMore } = action;
        let apiInboxes = payload.data;
        let endOfList = apiInboxes.length < INBOX_LIMIT;

        let localInboxes = state.inboxList.data
        let inboxes

        if((DV.searching || !isEmpty(DV.filterInfo) && !getMore)) {
          inboxes = [...apiInboxes];
        } else {
            inboxes = [...localInboxes, ...apiInboxes];
        }

        inboxes = inboxes.map(item => ({
            ...item,
            last_customer_message_timestamp: item.last_customer_message_time ?  new Date(item.last_customer_message_time).getTime() : 0,
        }));

        if(!getMore) {
           let indexedInboxes = {};
           inboxes.forEach(inbox => {
               if(indexedInboxes[inbox.id]) {
                   indexedInboxes[inbox.id] = mergeInbox(indexedInboxes[inbox.id], inbox)
               } else {
                   indexedInboxes[inbox.id] = inbox;
               }
           })
           const values = Object.values(indexedInboxes);
           const ordered = orderBy(values, "last_customer_message_timestamp", "desc");
            const sliced =ordered.slice(0, INBOX_LIMIT);
           inboxes = sliced
       }
        return {
            ...state,
            inboxList: {
                ...getBaseReduxObj(),
                data: inboxes,
                isSuccess: true,
                endOfList,
            },
        };
    },
    [Types.GET_INBOX_LIST + "_FAIL"]: (state, action) => {
        let { payload, getMore } = action;
        let data = getMore ? state.inboxList.data : [];

        return {
            ...state,
            inboxList: {
                ...getBaseReduxObj(),
                data,
                error: payload,
                isSuccess: false,
                endOfList: true,
            },
        };
    },

    //todo: GET_PRODUCTS
    [Types.GET_PRODUCTS]: (state, action) => {
        return {
            ...state,
            products: {
                ...getBaseReduxObj(),
                data: state.products.data,
                loading: true,
            },
        };
    },
    [Types.GET_PRODUCTS + "_SUCCESS"]: (state, action) => {
        return {
            ...state,
            products: {
                ...getBaseReduxObj(),
                ...action.payload,
                data: addAmountOfProduct(action.payload.data),
                isSuccess: true,
            },
        };
    },
    [Types.GET_PRODUCTS + "_FAIL"]: (state, action) => {
        return {
            ...state,
            products: {
                ...getBaseReduxObj(),
                error: action.payload,
                isSuccess: false,
            },
        };
    },

    //todo: GET_LIST_SELLER
    [Types.GET_LIST_SELLER]: (state, action) => {
        return {
            ...state,
            listSeller: {
                ...getBaseReduxObj(),
                loading: true,
            },
        };
    },
    [Types.GET_LIST_SELLER + "_SUCCESS"]: (state, action) => {
        return {
            ...state,
            listSeller: {
                ...getBaseReduxObj(),
                ...action.payload,
                isSuccess: true,
            },
        };
    },
    [Types.GET_LIST_SELLER + "_FAIL"]: (state, action) => {
        return {
            ...state,
            listSeller: {
                ...getBaseReduxObj(),
                error: action.payload,
                isSuccess: false,
            },
        };
    },

    //todo: GET ALL COMPANY
    [Types.GET_ALL_COMPANIES]: (state, action) => {
        return {
            ...state,
            allCompanies: {
                ...getBaseReduxObj(),
                loading: true,
            },
        };
    },
    [Types.GET_ALL_COMPANIES + "_SUCCESS"]: (state, action) => {
        let approved = action.payload.data.filter(
            (company) => company.staff_status === "approved",
        );
        let pending = action.payload.data.filter(
            (company) => company.staff_status === "pending",
        );
        let free = action.payload.data.filter(
            (company) => company.staff_status === null,
        );
        let data = [...approved, ...pending, ...free];
        return {
            ...state,
            allCompanies: {
                ...getBaseReduxObj(),
                data: data,
                isSuccess: true,
            },
        };
    },
    [Types.GET_ALL_COMPANIES + "_FAIL"]: (state, action) => {
        return {
            ...state,
            allCompanies: {
                ...getBaseReduxObj(),
                error: action.payload,
                isSuccess: false,
            },
        };
    },

    //todo: GET_LIST_SELLER
    [Types.GET_SEGMENT]: (state, action) => {
        return {
            ...state,
            segments: {
                ...getBaseReduxObj(),
                loading: true,
            },
        };
    },
    [Types.GET_SEGMENT + "_SUCCESS"]: (state, action) => {
        return {
            ...state,
            segments: {
                ...getBaseReduxObj(),
                ...action.payload,
                isSuccess: true,
            },
        };
    },
    [Types.GET_SEGMENT + "_FAIL"]: (state, action) => {
        return {
            ...state,
            segments: {
                ...getBaseReduxObj(),
                error: action.payload,
                isSuccess: false,
            },
        };
    },

    //todo: set data -----------------------------------------------------------------------------------

    //todo: SET_CURRENT_COMPANY
    [Types.SET_COMPANY]: (state, action) => {
        return {
            ...state,
            currentCompany: { ...action.payload },
            inboxList: getBaseReduxObj(),
        };
    },

    //todo: SET_TOKEN
    [Types.SET_TOKEN]: (state, action) => {
        return {
            ...state,
            token: {
                ...getBaseReduxObj(),
                data: action.payload,
                isSuccess: true,
            },
        };
    },

    //todo:SET INBOX LIST
    [Types.SET_INBOX_LIST]: (state, action) => {
        return {
            ...state,
            inboxList: {
                ...getBaseReduxObj(),
                data: action.payload,
                isSuccess: true,
            },
        };
    },

    [Types.SET_MULTI_INBOX]: (state, action) => {
        return {
            ...state,
            multi_inbox: action.payload,
        };
    },
    //todo:  SOCKET -------------------------------------------------------------------------------------

    [Types.CONNECT_INBOX]: (state, action) => {
        return {
            ...state,
            stateConnectInbox: action.payload,
        };
    },

    [Types.CONNECT_CHAT]: (state, action) => {
        return {
            ...state,
            stateConnectChat: action.payload,
        };
    },

    //todo:  APP STATE -------------------------------------------------------------------------------------
    //todo: handle open notification
    [Types.SET_PARAMS_OPEN_FROM_NOTIFICATION]: (state, action) => {
        return {
            ...state,
            inbox_id_notification: Math.trunc(action.payload.inbox),
            company_id_notification: Math.trunc(action.payload.company),
        };
    },

    //todo: handle network
    [Types.SET_NETWORK]: (state, action) => {
        return {
            ...state,
            network: action.payload,
        };
    },

    //todo: clear data
    [Types.CLEAR_DATA]: (state, action) => {
        return {
            ...state,
            [action.key]: {
                ...getBaseReduxObj(),
                loading: action.toLoading,
            },
        };
    },

    //todo: handle select inbox
    [Types.SELECT_INBOX]: (state, action) => {
        return {
            ...state,
            listInboxSelected: action.listInboxSelected,
        };
    },

    [Types.SET_MAX_INBOX_OPEN]: (state, action) => {
        LocalStorage.setMaxInboxOpen(action.number);
        return {
            ...state,
            maxInboxOpen: action.number,
        };
    },

    [Types.SET_CHAT_MODE]: (state, action) => {
        LocalStorage.setChatMode(action.chatMode);
        DV.chatMode = action.chatMode;
        return {
            ...state,
            chatMode: action.chatMode,
        };
    },

    [Types.GET_PRESETS]: (state, action) => {
        return {
            ...state,
            presets: {
                ...getBaseReduxObj(),
                loading: true,
            },
        };
    },
    [Types.GET_PRESETS + "_SUCCESS"]: (state, action) => {
        return {
            ...state,
            presets: {
                ...getBaseReduxObj(),
                ...action.payload,
                data: [...defaultPresets, ...action.payload?.data],
                isSuccess: true,
            },
        };
    },
    [Types.GET_PRESETS + "_FAIL"]: (state, action) => {
        return {
            ...state,
            presets: {
                ...getBaseReduxObj(),
                data: defaultPresets,
                error: action.payload,
                isSuccess: false,
            },
        };
    },
    [Types.SET_SELECTED_PRESET]: (state, action) => {
        return {
            ...state,
            selectedPreset: action.payload,
        };
    },
    [Types.SET_SELLER_INBOX_COUNT]: (state, action) => {
        return {
            ...state,
            inboxCount: action.payload || 0,
        };
    },
    [Types.SET_USER_STATUS]: (state, action) => {
        return {
            ...state,
            userStatus: action.payload,
        };
    },

    [Types.SHOW_SOURCE_INBOX]: (state, action) => {
        return {
            ...state,
            showInboxSource: action.payload,
        };
    },

    //todo: GET_REPLY_COMMANDS
    [Types.GET_REPLY_COMMANDS]: (state, action) => {
        return {
            ...state,
            replyCommands: {
                ...getBaseReduxObj(),
                loading: true,
            },
        };
    },
    [Types.GET_REPLY_COMMANDS + "_SUCCESS"]: (state, action) => {
        return {
            ...state,
            replyCommands: {
                ...getBaseReduxObj(),
                ...action.payload,
                isSuccess: true,
            },
        };
    },
    [Types.GET_REPLY_COMMANDS + "_FAIL"]: (state, action) => {
        return {
            ...state,
            replyCommands: {
                ...getBaseReduxObj(),
                error: action.payload,
                isSuccess: false,
            },
        };
    },

    [Types.SET_CALL_SERVICE]: (state, action) => {
        return {
            ...state,
            callService: action.payload,
        };
    },

    [Types.SET_CALLS_IS_HELD]: (state, action) => {
        return {
            ...state,
            callsIsHeld: action.payload,
        };
    },

    [Types.SET_INBOX_CALLING]: (state, action) => {
        return {
            ...state,
            inboxCalling: action.data || null,
        };
    },
    [Types.ADD_INBOX_CALLING]: (state, action) => {
        const old = [...state.inboxCalling];
        const index  = old.findIndex(item => item.sip_uri === action?.data.sip_uri)
        if(index>=0) {
            old[index] = action?.data
            return {
                ...state,
                inboxCalling: old
            }
        }
        return {
            ...state,
            inboxCalling: [...state.inboxCalling, action?.data],
        };
    },
    [Types.REMOVE_INBOX_CALLING]: (state, action) => {
        return {
            ...state,
            inboxCalling: state.inboxCalling.filter(inbox => inbox.id !== action.data.id),
        };
    },
    [Types.SET_SELLER_CALLING]: (state, action) => {
        return {
            ...state,
            sellerCalling: action.data,
        };
    },
    [Types.SET_CALL_IS_MERGED]: (state, action) => {
        return {
            ...state,
            callIsMerged: action.payload,
        };
    },
    [Types.SHOW_INBOX_CALL_ERROR]: (state, action) => {
        return {
            ...state,
            inboxCallError: [...state.inboxCallError, action.data],
        };
    },
    [Types.HIDE_INBOX_CALL_ERROR]: (state, action) => {
        return {
            ...state,
            inboxCallError: state.inboxCallError.filter(item => item.inboxId !== action.data.inboxId),
        };
    },

    [Types.SET_SETTINGS]: (state, action) => {
        return {
            ...state,
            settings: action.payload,
        };
    },
});
