import * as Sentry from "@sentry/browser";
import { ENV, DISABLE_SENTRY } from "config/Env";

const pjson = require("../../package.json");
const isLocalHost = window.location.origin.toString().includes("localhost");

const DSN = "https://3a928eaaf13840baa4cffbe0d60f8105@sentry.io/1250164";
//const DSN = 'https://3a928eaaf13840baa4cffbe0d60f8105@sentry.io/1250164';

if (!isLocalHost && !DISABLE_SENTRY) {
    Sentry.init({
        dsn: DSN,
        maxBreadcrumbs: 50,
        debug: false,
        environment: ENV,
        release: pjson.version,
        integrations: [new Sentry.Integrations.Breadcrumbs({
            console: false,
        })],
    });
}

export const showReportDialog = (eventId) => {
    Sentry.showReportDialog({
        dsn: DSN,
        eventId,
        lang: "vi",
        title: "Xin lỗi vì sự bất tiện này!",
        subtitle: "Chúng tôi sẽ phản hồi bạn nhanh nhất có thể.",
        subtitle2: "Cảm ơn bạn đã đóng góp để phát triển DooPage",
        labelComments: "Góp ý / ý kiến của bạn:",
        labelClose: "Đóng",
        labelSubmit: "Gửi",
        errorGeneric: "Có lỗi khi kết nối với máy chủ, bạn vui lòng thử lại!",
        errorFormEntry: "Bạn chưa cung cấp đủ thông tin để chúng tôi có thể hỗ trợ bạn tốt nhất, vui lòng kiểm tra lại!",
        successMessage: "Cảm ơn bạn đã tin dùng DooPage!",
    });
};
