import Dexie from 'dexie';
import DV from "./variables/DV";
import { APP_VERSION } from "./variables/staticValue";
import { getBuildNumber, isIphoneOrIpad } from "./helper/helper";
import { toNumber } from "lodash";

const VERSION = toNumber(getBuildNumber()) || 1;

const createNewDB = () => {
    if(isIphoneOrIpad()) return
    const newIndexDB = new Dexie('doopage_center');
    newIndexDB.version(VERSION).stores({
        messages: 'deduplicate_id, id, inbox, message_type, created_timestamp', // Primary key and indexed props
        inboxes: 'id, company_id, platform_id, platform_type, last_customer_message_timestamp, out_of_7d, out_of_24h, phone, full_name', // Primary key and indexed props

    });
    DV.indexDB = newIndexDB;
}

export const initIndexDB = async () => {
   if(isIphoneOrIpad()) return
    new Dexie("doopage_center")
        .open()
        .then(function (db) {
            console.log('Index DB version', {current: db.verno, latest: VERSION})
          if(db.verno !==  VERSION) {
              db.delete().then(() => {
                 createNewDB();
              })
          } else {
              createNewDB();
          }
        })
        .catch("NoSuchDatabaseError", function (e) {
            // Database with that name did not exist
            console.error("Database not found");
            createNewDB();
        })
        .catch(function (e) {
            console.error("Error: " + e);
        });

}


export const getInboxesFromIndexDb = async () => {
    if(isIphoneOrIpad()) {
        return []
    }
    return DV.indexDB?.inboxes
        .where({ company_id: DV.company.id })
        .reverse()
        .sortBy("last_customer_message_timestamp") ?? [].map(inbox=> ({...inbox, isLocal: true}));
};
