import { setLogStyle, useLogStyle } from "config/LogConfig";
import {
    dangerColor,
    infoColor,
    successColor,
    warningColor,
} from "variables/color";
import DV from "variables/DV";
import { CHAT_MODE } from "variables/staticValueMessage";

export default class LocalStorage {
    static clearLocal = () => {
        console.log(
            useLogStyle + "LocalStorage CLEAR",
            setLogStyle(warningColor)
        );
        try {
            localStorage.clear();
        } catch (error) {
            console.log(
                useLogStyle + "LocalStorage CLEAR \n ERROR:",
                setLogStyle(dangerColor),
                error.message
            );
        }
    };

    static setToLocal = (key, value) => {
        console.log(
            useLogStyle + "LocalStorage SET " + key + " TO LOCAL",
            setLogStyle(infoColor),
            value
        );
        try {
            localStorage.setItem(key, JSON.stringify(value));
        } catch (error) {
            console.log(
                useLogStyle + "LocalStorage SET " + key + " TO LOCAL \n ERROR:",
                setLogStyle(dangerColor),
                error.message
            );
        }
    };

    static getFromLocal = (key) => {
        let data = null;
        try {
            let res = localStorage.getItem(key);
            data = JSON.parse(res);
            console.log(
                useLogStyle + "LocalStorage GET " + key + " FROM LOCAL",
                setLogStyle(successColor),
                data
            );
        } catch (error) {
            console.log(
                useLogStyle +
                    "LocalStorage GET " +
                    key +
                    " FROM LOCAL \n ERROR:",
                setLogStyle(dangerColor),
                error.message
            );
        }

        return data;
    };
    static getOrderInputData = (inboxId) => {
        return LocalStorage.getFromLocal(
            "inbox/" + inboxId + "/orderInputData"
        );
    };

    static setOrderInputData = (inboxId, data) => {
        LocalStorage.setToLocal("inbox/" + inboxId + "/orderInputData", data);
    };

    static getStaffDeviceId = () => {
        return LocalStorage.getFromLocal("deviceId");
    };

    static setStaffDeviceId = (deviceId = null) => {
        LocalStorage.setToLocal("deviceId", deviceId);
    };

    static getToken = () => {
        return LocalStorage.getFromLocal("token");
    };

    static setToken = (token = null) => {
        LocalStorage.setToLocal("token", token);
    };

    static getRefreshToken = () => {
        return LocalStorage.getFromLocal("refreshToken");
    };
    static setRefreshToken = (token = null) => {
        LocalStorage.setToLocal("refreshToken", token);
    };

    static getChatMode = () => {
        return LocalStorage.getFromLocal("chatMode") || CHAT_MODE.multi;
    };

    static setChatMode = (data = null) => {
        LocalStorage.setToLocal("chatMode", data);
    };

    static getReloadInfo = () => {
        return (
            LocalStorage.getFromLocal("ri") || {
                reloadCount: 0,
                reloadVersion: "",
            }
        );
    };

    static setReloadInfo = (data = null) => {
        LocalStorage.setToLocal("ri", data);
    };

    static getListInboxSelected = () => {
        if (!DV.company) return [null];
        return (
            LocalStorage.getFromLocal(
                "company/" + DV.company.id + "/listInboxSelected"
            ) || [null]
        );
    };

    static setListInboxSelected = (data = null) => {
        if (DV?.company?.id) {
            LocalStorage.setToLocal(
                "company/" + DV.company.id + "/listInboxSelected",
                data
            );
        }
    };

    static getMaxInboxOpen = () => {
        return LocalStorage.getFromLocal("maxInboxOpen") || 1;
    };

    static setMaxInboxOpen = (data = null) => {
        LocalStorage.setToLocal("maxInboxOpen", data);
    };

    static getFCMToken = () => {
        return LocalStorage.getFromLocal("fcmToken");
    };

    static setFCMToken = (data = "") => {
        LocalStorage.setToLocal("fcmToken", data);
    };

    static getCompany = () => {
        return LocalStorage.getFromLocal("company");
    };

    static setCompany = (data = null) => {
        LocalStorage.setToLocal("company", data);
    };

    static getOrder = () => {
        return LocalStorage.getFromLocal("order") || {};
    };

    static setOrder = (data = {}) => {
        LocalStorage.setToLocal("order", data);
    };
    static getListInbox = () => {
        if (!DV.company) return [];
        return (
            LocalStorage.getFromLocal(
                "company/" + DV.company.id + "/listInbox"
            ) || []
        );
    };

    static setListInbox = (data = []) => {
        if (DV?.company?.id) {
            LocalStorage.setToLocal(
                "company/" + DV.company.id + "/listInbox",
                data
            );
        }
    };

    static setFakeOwnerId = (id) => {
        LocalStorage.setToLocal("doopage_fake_owner_id", parseInt(id));
    };

    static setUser = (data = {}) => {
        LocalStorage.setToLocal("user", data);
    };

    static setFilter = (filterData) => {
        LocalStorage.setToLocal(
            "company/" + DV.company.id + "/filter",
            filterData
        );
    };

    static getFilter = () => {
        if (!DV.company) return {};
        return (
            LocalStorage.getFromLocal("company/" + DV.company.id + "/filter") ??
            {}
        );
    };

    static setLang = (lang) => {
        LocalStorage.setToLocal("lang", lang);
    };

    static getLang = () => {
        return LocalStorage.getFromLocal("lang") || "vi";
    };

    static getShowInboxSource = () => {
        const {
            showInboxSource,
        } =  LocalStorage.getFilter();
        return showInboxSource;
    }

    static getPermanentIP = () => {
        return LocalStorage.getFromLocal('ipInfo_v2') || {};
    };

    static setPermanentIP = (data = null) => {
        LocalStorage.setToLocal('ipInfo_v2', data);
    };
}
