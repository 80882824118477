import { showSnackbarVariant } from "@doopage/react-ui-kit";
import { t } from "@lingui/macro";
import { SIPWS_URL } from "config/Env";
import EventEmitter from "eventemitter3";
import store from "redux/store";
import Types from "redux/type";
import { Invitation, Inviter, NameAddrHeader, SessionState, UserAgent, Web } from "sip.js";
import { setInboxCalling, showInboxCallError } from "../redux/actionCreator/actionSelectInbox";

class Transport extends Web.Transport {
    async connect() {
        let d = 100;
        while (true) {
            try {
                return await super.connect();
            } catch (e) {
                await new Promise(done => setTimeout(done, d));
                d *= 2;
            }
        }
    }
}

class WebAudioSessionDescriptionHandler extends Web.WebAudioSessionDescriptionHandler {
    _localMediaStream = null;
    _remoteMediaStreamSource = null;

    constructor(logger, mediaStreamFactory, sessionDescriptionHandlerConfiguration) {
        Object.assign(sessionDescriptionHandlerConfiguration.peerConnectionConfiguration, {
            rtcpMuxPolicy: "negotiate",
        });
        super(logger, mediaStreamFactory, sessionDescriptionHandlerConfiguration);
    }

    setLocalMediaStream(stream) {
        this._localMediaStream = stream;
        return super.setLocalMediaStream(this.initLocalMediaStream(stream));
    }

    getRemoteMediaStreamSource() {
        if (!this._remoteMediaStreamSource) {
            this._remoteMediaStreamSource = WebAudioSessionDescriptionHandler.audioContext.createMediaStreamSource(this.remoteMediaStream);
        }
        return this._remoteMediaStreamSource;
    }

    joinWith(peer) {
        if (!WebAudioSessionDescriptionHandler.audioContext) {
            throw new Error("SessionManagerSessionDescriptionHandler.audioContext undefined.");
        }
        const ourNewInboundStreamSource = this.getRemoteMediaStreamSource();
        // noinspection JSUnresolvedReference
        const peerOutboundStreamDestination = peer.localMediaStreamDestinationNode;
        if (peerOutboundStreamDestination === undefined) {
            throw new Error("Peer outbound (local) stream local media stream destination is undefined.");
        }
        ourNewInboundStreamSource.connect(peerOutboundStreamDestination);

        // noinspection JSUnresolvedReference
        const peerNewInboundStreamSource = peer.getRemoteMediaStreamSource();
        // noinspection JSUnresolvedReference
        const ourOutboundStreamDestination = this.localMediaStreamDestinationNode;
        if (ourOutboundStreamDestination === undefined) {
            throw new Error("Our outbound (local) stream local media stream destination is undefined.");
        }
        peerNewInboundStreamSource.connect(ourOutboundStreamDestination);
    }

    unJoin() {
        if (this._remoteMediaStreamSource) {
            this.getRemoteMediaStreamSource().disconnect();
        }
    }

    close() {
        if (this._localMediaStream) {
            this._localMediaStream.getTracks().forEach((track) => {
                if (track.readyState === "live") {
                    track.stop();
                }
            });
        }
        super.close();
    }
}

const sessionDescriptionHandlerFactory = (session, options) => {
    const iceGatheringTimeout = options.iceGatheringTimeout ?? 5000;
    const sessionDescriptionHandlerConfiguration = {
        iceGatheringTimeout,
        peerConnectionConfiguration: {
            ...Web.defaultPeerConnectionConfiguration(),
            ...options.peerConnectionConfiguration,
        },
    };
    const logger = session.userAgent.getLogger("sip.WebAudioSessionDescriptionHandler");
    return new WebAudioSessionDescriptionHandler(logger, Web.defaultMediaStreamFactory(), sessionDescriptionHandlerConfiguration);
};

export class CallServiceDelegate {
    constructor(callService) {
        this.callService = callService;
    }

    // noinspection JSUnusedGlobalSymbols
    onRegistered() {
        this.callService.isRegistered = true;
        store.dispatch({
            type: Types.SET_CALL_SERVICE,
            payload: this.callService,
        });
    }

    // noinspection JSUnusedGlobalSymbols
    onUnregistered() {
        this.callService.isRegistered = false;
        // this.callService.notify("Dịch vụ tổng đài tạm thời không khả dụng", "warning");
    }

    // noinspection JSUnusedGlobalSymbols
    onCallReceived(session) {
        const { callReceivedModal } = this.callService;
        if (callReceivedModal) {
            callReceivedModal.open(this.callService, session);
        }
    }

    // noinspection JSUnusedGlobalSymbols
    onCallCreated(session) {
        this.callService.session = session;
        if (session instanceof Inviter) {
            session.delegate = session.delegate || {};
            session.stateChange.addListener(async (state) => {
                switch (state) {
                    case SessionState.Established: {
                        const dialog = session.dialog;
                        const inviterOptions = {
                            params: {
                                fromTag: dialog.localTag,
                                toUri: dialog.remoteURI.toString(),
                                toTag: dialog.remoteTag,
                                callId: dialog.callId,
                                cseq: dialog.localSequenceNumber + 1,
                            },
                            extraHeaders: dialog.routeSet.reverse().map(r => `Route: ${r}`),
                        };
                        localStorage.setItem("sip_recover_options", JSON.stringify(inviterOptions));
                        break;
                    }
                    case SessionState.Terminated:
                        if (this.callService.managedSessions.length === 1) {
                            localStorage.removeItem("sip_recover_options");
                            localStorage.removeItem("sip_recover_servers");
                            for (const id of ["local", "remote"]) {
                                const el = document.getElementById(`sip-session-${session.id}-${id}`);
                                if (el) {
                                    el.remove();
                                }
                            }
                        }
                        break;
                }
            });
        }
    }

    // noinspection JSUnusedGlobalSymbols
    onCallHangup() {
        this.callService.session = null;
    }

    // noinspection JSUnusedGlobalSymbols
    onCallHold(session, held) {
        const callsIsHeld = store.getState();
        // Không thể biết cuộc gọi nào hold/unhold, nên lấy lại toàn bộ danh sách
        store.dispatch({
            type: Types.SET_CALLS_IS_HELD,
            payload: {
                ...callsIsHeld,
                [session.id]: held,
            },
        });
    }
}

export class CallServiceHelper {
    // noinspection JSUnusedGlobalSymbols
    callReceivedModal = null;

    isRegistered = null;

    events = new EventEmitter();

    compatibleDirect = false;

    // noinspection JSUnusedLocalSymbols
    constructor({ aor, username, password, domain, hotlines }) {
        // noinspection JSUnusedGlobalSymbols
        this.hotlines = hotlines;
        this.delegate = new CallServiceDelegate(this);
        this.domain = domain;

        this.options = {
            aor,
            media: {
                constraints: {
                    audio: true,
                    video: false,
                },
                local: session => {
                    const audio = document.createElement("audio");
                    audio.autoplay = true;
                    audio.id = `sip-session-${session.id}-local`;
                    document.body.append(audio);
                    return { audio };
                },
                remote: session => {
                    const audio = document.createElement("audio");
                    audio.autoplay = true;
                    audio.id = `sip-session-${session.id}-remote`;
                    document.body.append(audio);
                    return { audio };
                },
            },
            registererOptions: {
                expires: 5 * 60,
                refreshFrequency: 50,
            },
            delegate: this.delegate,
            autoStop: false,
            reconnectionAttempts: Infinity,
            reconnectionDelay: 1, // 1s
            userAgentOptions: {
                authorizationUsername: username,
                authorizationPassword: password,
                contactName: username,
                displayName: "DooPage",
                logLevel: "error",
                transportConstructor: Transport,
                sessionDescriptionHandlerFactory,
            },
        };
        this.user = this.createUser(this.options);
    }

    get managedSessions() {
        return this.user.managedSessions;
    }

    createUser(options) {
        let sipWs = SIPWS_URL;
        const s = localStorage.getItem("sip_recover_servers");
        if (s) {
            const servers = JSON.parse(s);
            if (servers.length > 0) {
                const url = new URL(sipWs);
                url.pathname += "_recover";
                url.searchParams.set("node", Array.of(...servers).pop());
                sipWs = url.toString();
            }
        }
        return new Web.SessionManager(sipWs, options);
    }

    // muteRemoteAudio(session, value = true) {
    //     this.remote_audio.muted = value;
    // }

    async checkCompatible() {
        // noinspection JSCheckFunctionSignatures
        const c = new RTCPeerConnection({ rtcpMuxPolicy: "negotiate" }); // rtcpMuxPolicy=required
        const sd = new RTCSessionDescription({
            type: "offer",
            sdp: [
                "v=0",
                "o=- 0 0 IN IP4 127.0.0.1",
                "s=doopage",
                "t=0 0",
                "m=audio 1 UDP/TLS/RTP/SAVPF 0",
                "a=ice-ufrag:00000000",
                "a=ice-pwd:0000000000000000000000000000000",
                "",
            ].join("\n"),
        });
        try {
            await c.setRemoteDescription(sd);
            return true; // SDP hợp lệ
        } catch (e) {
            if (e instanceof DOMException) {
                const matches = /^Failed to execute 'setRemoteDescription' on 'RTCPeerConnection': Failed to set remote offer sdp: (.+).$/.exec(e.message);
                if (matches) {
                    const m = matches.pop();
                    switch (m) {
                        case "Called with SDP without DTLS fingerprint":
                            // this.notify(t`Trình duyệt của bạn yêu cầu DTLS nên không thể sử dụng Call.`, "warning");
                            return false;
                        case "RTCP-MUX is not enabled when it is required":
                            // this.notify(t`Trình duyệt của bạn yêu cầu RTCP nên không thể sử dụng Call.`, "warning");
                            return false;
                    }
                    // return false;
                }
            }
            throw e;
        }
    }

    async checkMedia() {
        try {
            const result = await navigator.mediaDevices.getUserMedia(this.options.media.constraints);
            const tracks = result.getTracks();
            tracks.forEach(function(track) {
                track.stop();
            });
            return true;
        } catch (e) {
            if (e instanceof DOMException) {
                // noinspection JSDeprecatedSymbols,SpellCheckingInspection
                switch (e.code) {
                    case DOMException.NOT_FOUND_ERR:
                    case DOMException.NOT_SUPPORTED_ERR:
                    case DOMException.VALIDATION_ERR:
                        // noinspection SpellCheckingInspection
                        this.notify(t`Máy tính của bạn không đáp ứng được điều kiện nhận cuộc gọi từ tổng đài.`, "error");
                        return;
                    case DOMException.INVALID_ACCESS_ERR:
                    case DOMException.SECURITY_ERR:
                        // noinspection SpellCheckingInspection
                        this.notify(t`Máy tính của bạn đang chặn việc sử dụng Micro để nhận cuộc gọi.`, "error");
                        return;
                    // Nếu result.message=='Permission denied' thì tương đương với DOMException.ABORT_ERR
                    case DOMException.ABORT_ERR:
                        // noinspection SpellCheckingInspection
                        this.notify(t`Bạn đã từ chối cấp quyền sử dụng Micro để nhận cuộc gọi.`, "error");
                        return;
                    case 0:
                        switch (e.name) {
                            case "NotAllowedError":
                                this.notify(t`Bạn chưa cấp quyền Micro để nhận cuộc gọi.`, "error");
                                return;
                        }
                }
            }
            this.notify(t`Có lỗi khi khởi tạo thiết bị âm thanh: ${e}`, "error");
        }
        return false;
    }

    async connect() {
        if (!await this.checkCompatible()) {
            const el = <>
                {t`Trình duyệt của bạn không hỗ trợ cuộc gọi phổ thông.`}&nbsp;
                <a href="https://how.doopage.com/thao-tac-danh-cho-nhan-vien/cai-dat-tong-dai-dien-thoai/trinh-duyet-khong-ho-tro-tong-dai" target="_blank">{t`Xem chi tiết`}</a> !
            </>;
            this.notify(el, "warning");
            // return; // TODO: Không cho sử dụng call ?
        } else {
            this.compatibleDirect = true;
            // this.notify(t`Trình duyệt của bạn tương thích với chức năng Call`, "info");
        }
        if (!await this.checkMedia()) {
            // this.notify(t`Chức năng Call sẽ bị tắt`, "warning");
            return;
        }
        await this.user.connect();
        await this.user.register();
    }

    async disconnect() {
    }

    // noinspection JSUnusedGlobalSymbols
    async answer(session) {
        try {
            if (this.user) {
                return await this.user.answer(session);
            }
        } catch (e) {
            // noinspection SpellCheckingInspection
            this.notify(t`Có lỗi xảy ra khi trả lời cuộc gọi: [${e.name}] ${e.message}`, "error");
        }
    }

    async hangup(session) {
        if (!this.user.sessionExists(session)) {
            // Hangup thường được gọi lại nhiều lần để cố gắng HANGUP những cuộc gọi đang dở
            // Tránh trường hợp do vô tình xử lý sai khiến cuộc gọi còn chạy. Nếu cuộc gọi không tồn tại thì bỏ qua
            return;
        }
        if (this.user) {
            // noinspection SpellCheckingInspection
            if (session instanceof Invitation) {
                // Nếu là cuộc gọi đến
                // noinspection SpellCheckingInspection
                switch (session.state) {
                    case SessionState.Terminating:
                    case SessionState.Terminated:
                        // Nếu đang kết thúc thì không làm gì
                        break;
                    case SessionState.Initial:
                        // Đánh dấu agent bận (486 - Busy Here)
                        await session.reject({ statusCode: 486 });
                        break;
                    default:
                        // Nếu đã nhận cuộc gọi thì để sip.js xử lý huỷ cuộc gọi (Gửi CANCEL hoặc BYE)
                        await this.user.hangup(session);
                }
            } else {
                // Cuộc gọi đi
                await this.user.hangup(session);
            }
        }
    }

    async call(destination, inviterOptions = {}) {
        // noinspection SpellCheckingInspection
        if (this.user) {
            // Gọi bằng user để thực hiện >1 cuộc gọi đồng thời
            // noinspection JSUnusedGlobalSymbols
            return await this.user.call(destination, inviterOptions, {
                requestDelegate: {
                    onTrying: (res) => {
                        if (this.user.managedSessions.length === 1) {
                            // Chỉ set localStorage cho cuộc gọi đầu tiên
                            const headers = Array.of(...res.message.headers.Server).map(x => x.raw);
                            localStorage.setItem("sip_recover_servers", JSON.stringify(headers));
                        }
                    },
                    onReject: (res) => {
                        switch (res.message.statusCode) {
                            case 486:
                            case 600:
                                return this.inbox_notify(res.message.callId, t`Máy bận` + ` (${res.message.statusCode})`, "warning");
                            case 603:
                            case 608:
                                return this.inbox_notify(res.message.callId, t`Đã từ chối` + ` (${res.message.statusCode})`, "warning");
                            case 488:
                            case 606:
                                return this.inbox_notify(res.message.callId, t`Không thể kết nối` + ` (${res.message.statusCode})`, "warning");
                            default:
                                return this.inbox_notify(res.message.callId, t`Không thể liên lạc` + ` (${res.message.statusCode})`, "warning");
                        }
                        // this.notify(t`Lỗi khi thực hiện cuộc gọi: ${res.message.reasonPhrase}`, "error");
                    },
                },
            });
        }
    }

    async hold(session, value = null) {
        if (this.user) {
            if (typeof value == "boolean") {
                if (value) {
                    await this.user.hold(session);
                } else {
                    await this.user.unhold(session);
                }
            } else if (!this.user.isHeld(session)) {
                await this.user.hold(session);
            } else {
                await this.user.unhold(session);
            }
        }
    }

    async mute(session, value) {
        if (this.user) {
            if (typeof value == "boolean") {
                if (value) {
                    await this.user.mute(session);
                } else {
                    await this.user.unmute(session);
                }
            } else if (!this.user.isMuted()) {
                await this.user.mute(session);
            } else {
                await this.user.unmute(session);
            }
        }
    }

    async merge() {
        return Web.startLocalConference(this.managedSessions.map(s => s.session));
    }

    async unmerge() {
        this.managedSessions.map(s => s.session.sessionDescriptionHandler.unJoin());
    }

    isSessionExists(target) {
        return this.managedSessions.map(s => s.session).find(s => s.remoteIdentity.uri.toString() === target);
    }

    async blindRefer(session, destination) {
        await session.refer(UserAgent.makeURI(destination));
    }

    async attendedRefer(session, targetSession) {
        if (typeof targetSession === "string") {
            targetSession = this.managedSessions.map(s => s.session).find(s => s.remoteIdentity.uri.toString() === targetSession);
            if (!targetSession) {
                return;
            }
        }
        try {
            const uri = UserAgent.makeURI("sip:pickup@0.0.0.0");
            // noinspection SpellCheckingInspection
            Object.assign(uri.parameters, {
                callid: targetSession.dialog.callId,
                fromtag: targetSession.dialog.localTag,
                totag: targetSession.dialog.remoteTag,
            });
            await session.refer(uri);
        } catch (e) {
            this.notify(t`Có lỗi khi Attended Refer: ${e}`, "error");
            await this.hangup(session);
        }
    }

    async redirect(session, destination) {
        try {
            // noinspection SpellCheckingInspection
            if (session instanceof Invitation) {
                // noinspection JSCheckFunctionSignatures
                const nameAddr = new NameAddrHeader(destination);
                // Chuyển hướng (302 Moved Temporarily)
                // noinspection JSUnresolvedVariable
                await session.incomingInviteRequest.redirect([nameAddr], {
                    statusCode: 302,
                });
            }
        } catch (e) {
            this.notify(t`Có lỗi khi chuyển tiếp cuộc gọi: ${e}`, "error");
            await this.hangup(session);
        }
    }

    async recover() {
        try {
            const s = localStorage.getItem("sip_recover_options");
            if (s) {
                const inviterOptions = JSON.parse(s);
                return await this.call(inviterOptions.params.toUri, inviterOptions);
            }
        } catch (e) {
            store.dispatch(setInboxCalling([]));
            this.notify(t`Lỗi khi khôi phục cuộc gọi: ${e}`, "error");
        }
        store.dispatch(setInboxCalling([]));
    }

    notify(message, level) {
        showSnackbarVariant(level)(message);
    }

    showError(sip_uri, error) {
        const reduxState = store.getState();
        const currentInbox = reduxState?.inboxCalling?.find(inbox => inbox.sip_uri === sip_uri);
        if (currentInbox.id) {
            store.dispatch(showInboxCallError(currentInbox.id, error));
        }
    }

    inbox_notify(callId, message, level) {
        this.events.emit(callId, { notify: { message, level } });
    }
}
