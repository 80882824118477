import { applyMiddleware, createStore } from "redux";
import { createLogger } from "redux-logger";
import thunk from "redux-thunk";
import { isDebug } from "../config/LogConfig";
import reducer from "./reducer";

const middleware = isDebug()
    ? applyMiddleware(thunk, createLogger())
    : applyMiddleware(thunk);

const store = createStore(reducer, middleware);

export default store;
