import React, { PureComponent } from "react";
import { LIST_DOMAIN } from "variables/staticValue";
import DV from "../variables/DV";

export default class MainLogo extends PureComponent {
    constructor(props) {
        super(props);
        this.logo = LIST_DOMAIN[DV.partner].logo;
    }

    render() {
        return <img src={this.logo} alt="logo" width={200} />;
    }
}
