import {
    showErrorSnackbar,
    showWarningSnackbar,
    showSuccessSnackbar,
} from "@doopage/react-ui-kit";
import { t } from "@lingui/macro";
import { SERVER_BASE_URL } from "config/Env";
import {
    closeSocketChat,
    closeSocketInbox,
    createSocketChat,
    createSocketInbox,
} from "config/Socket";
import PropTypes from "prop-types";
import React, { memo, useEffect, useRef, useState } from "react";
import { useDispatch } from "react-redux";
import { handleConnection } from "redux/actionCreator/action";
import DV from "variables/DV";
import { trans } from "../config/LinguiConfig";

const inBrowser = typeof navigator !== "undefined";

// these browsers don't fully support navigator.onLine, so we need to use a polling backup
const unsupportedUserAgentsPattern =
    /Windows.*Chrome|Windows.*Firefox|Linux.*Chrome/;

const ping = ({ url, timeout }) => {
    return new Promise((resolve) => {
        const isOnline = () => resolve(true);
        const isOffline = () => resolve(false);

        const xhr = new XMLHttpRequest();

        xhr.onerror = isOffline;
        xhr.ontimeout = isOffline;
        xhr.onreadystatechange = () => {
            if (xhr.readyState === xhr.HEADERS_RECEIVED) {
                if (xhr.status) {
                    isOnline();
                } else {
                    isOffline();
                }
            }
        };

        xhr.open("HEAD", url);
        xhr.timeout = timeout;
        xhr.send();
    });
};

const defaultPollingConfig = {
    enabled:
        inBrowser && unsupportedUserAgentsPattern.test(navigator.userAgent),
    url: SERVER_BASE_URL + "/ping",
    timeout: 5000,
    interval: 5000,
};

const defaultProps = {
    polling: true,
    wrapperType: "span",
};

const propTypes = {
    polling: PropTypes.oneOfType([
        PropTypes.shape({
            url: PropTypes.string,
            interval: PropTypes.number,
            timeout: PropTypes.number,
        }),
        PropTypes.bool,
    ]),
    wrapperType: PropTypes.string,
};

// base class that detects offline/online changes
const Network = ({ polling }) => {
    const [online, setOnline] = useState(() =>
        inBrowser && typeof navigator.onLine === "boolean"
            ? navigator.onLine
            : true
    );

    const pollingId = useRef(null);
    const mounded = useRef(false);

    const getPollingConfig = () => {
        switch (polling) {
            case true:
                return defaultPollingConfig;
            case false:
                return { enabled: false };
            default:
                return { ...defaultPollingConfig, ...polling };
        }
    };

    const goOnline = () => {
        if (!online) setOnline(true);
    };

    const goOffline = () => {
        if (online) setOnline(false);
    };

    const startPolling = () => {
        const { interval } = getPollingConfig();
        pollingId.current = setInterval(() => {
            const { url, timeout } = getPollingConfig();
            ping({ url, timeout }).then((online) => {
                online ? goOnline() : goOffline();
            });
        }, interval);
    };

    const stopPolling = () => {
        clearInterval(pollingId.current);
    };

    useEffect(() => {
        window.addEventListener("online", goOnline);
        window.addEventListener("offline", goOffline);

        if (getPollingConfig().enabled) startPolling();

        return () => {
            window.removeEventListener("online", goOnline);
            window.removeEventListener("offline", goOffline);

            if (pollingId) stopPolling();
        };
    });

    const dispatch = useDispatch();
    useEffect(() => {
        if (!online && mounded.current)
            showWarningSnackbar(t`Vui lòng kiểm tra lại kết nối`);

        if (online && mounded.current)
            showSuccessSnackbar(t`Đã kết nối với hệ thống`);

        DV.network = online;

        dispatch(handleConnection(online));

        if (!online) {
            closeSocketChat();
            closeSocketInbox();
        } else {
            createSocketChat();
            createSocketInbox();
        }

        if (!mounded.current) mounded.current = true;
    }, [online]);

    return null;
};

Network.propTypes = propTypes;
Network.defaultProps = defaultProps;

export default memo(Network);
