export const INTRO_CLASS_MENU_ICON = "menu";
export const INTRO_CLASS_PANEL_MODE = "panel-mode";
export const INTRO_CLASS_FILTER = "filter";
export const INTRO_CLASS_INBOX_LIST = "inbox-list";
export const INTRO_CLASS_AVATAR_WITHOUT_SELLER = "avatar-without-seller";
export const INTRO_CLASS_AVATAR_WITH_SELLER = "avatar-with-seller";
export const INTRO_CLASS_UNREPLIED = "unreplied";
export const INTRO_CLASS_SEGMENT = "segment";
export const INTRO_CLASS_SHOW_OTHER_SELLER_INBOX = "show-other-seller-inbox";
export const INTRO_CLASS_CHANGE_COMPANY = "change-company";
