const Config = process.env;

export const WITH_CREDENTIALS = Config.REACT_APP_WITH_CREDENTIALS === "true";
export const SERVER_BASE_URL = Config.REACT_APP_API_URL || "";
export const CLID = Config.REACT_APP_CLIENT_ID;
export const FACEBOOK_APP_ID = Config.REACT_APP_FACEBOOK_APP_ID;
export const GA_ID = Config.REACT_APP_GA;
export const DOOPAGE_CENTER = Config.REACT_APP_DOOPAGE_CENTER;
export const ENV = Config.REACT_APP_BASE;
export const SOCKET = Config.REACT_APP_SOCKET;
export const DOOPAGE_DASHBOARD = Config.REACT_APP_DOOPAGE_DASHBOARD;
export const SIPWS_URL = Config.REACT_APP_DOOPAGE_SIPWS_URL;
export const DISABLE_SENTRY = !!Config.REACT_APP_DISABLE_SENTRY;
export const SIP_RECORD_URL = Config.REACT_APP_SIP_RECORD_URL ?? `${SERVER_BASE_URL}/pcapplay`;

export const IS_SERVER_LOCAL = SERVER_BASE_URL.toString().includes("localhost");
